import React from "react"
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";


const Nav = () => {
    const [open, setOpen] = React.useState(false);
    const toggleMenuOpen = () => { setOpen(!open); };
    
    return (
        <div>
            <div className="nav">
                <div className="absolute inset-y-0 right-4 flex items-center md:hidden">
                    {/* Mobile menu button */}
                    <button 
                        type="button"
                        className="inline-flex items-center justify-center p-2 text-dl-green hover:bg-dl-green hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        aria-controls="mobile-menu"
                        aria-expanded="false"
                        onClick={toggleMenuOpen}
                    >
                        <span className="sr-only">Open main menu</span>
                        {!open ? (
                            <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                            </svg>
                        ) : (
                            <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        )}
                    </button>
                </div>
                <div className="nav-info">
                    <div className="nav-item"><Link to="/eyewear">Eyewear</Link></div>
                    <div className="nav-item"><Link to="/diensten">Diensten</Link></div>
                    <div className="nav-item"><Link to="/glazen">Glazen</Link></div>
                    <div className="nav-item"><Link to="/lenzen">Lenzen</Link></div>
                </div>
                <div className="nav-home">
                    <div className="nav-item">
                        <Link to="/">
                            <StaticImage
                                src="../images/logo.png"
                                height={28}
                                formats={["AUTO", "WEBP", "AVIF"]}
                                alt="Optiek De Leersnijder"
                                placeholder="none"
                                className="lg:block"
                            />
                        </Link>
                    </div>
                </div>
                <div className="nav-action">
                    <div className="nav-item text-dl-green"><a href="https://eyecatchonline.com/nl/" target="_blank" rel="noreferrer">Webshop</a></div>
                    <div className="nav-item"><Link to="/agenda">Afspraak maken</Link></div>
                    <div className="nav-item"><Link to="/over-ons">Over ons</Link></div>
                    <div className="nav-item"><Link to="/contact">Contact</Link></div>
                </div>
            </div>
            {/* Mobile menu, show/hide based on menu state. */}
            { open &&
                <div className="md:hidden" id="mobile-menu">
                    <div className="px-6 pt-2 pb-3">
                        {/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" */}
                        {/* <a href="#" className="bg-dl-orange bg-opacity-20 text-black block px-3 py-2 border-b border-gray-100 text-base font-medium" aria-current="page">Eyewear</a> */}
                        <Link to="/eyewear" className="mobile-nav-item">Eyewear</Link>
                        <Link to="/diensten" className="mobile-nav-item">Diensten</Link>
                        <Link to="/glazen" className="mobile-nav-item">Glazen</Link>
                        <Link to="/lenzen" className="mobile-nav-item">Lenzen</Link>
                    </div>
                    <div className="px-6 pt-2 pb-3">
                        <a className="mobile-nav-item" href="https://eyecatchonline.com/nl/" target="_blank" rel="noreferrer">Webshop</a>
                        <div className="nav-item"><Link to="/agenda">Afspraak maken</Link></div>
                        <Link to="/over-ons" className="mobile-nav-item">Over ons</Link>
                        <Link to="/contact" className="mobile-nav-item">Contact</Link>
                    </div>
                </div>
            }     
        </div>
    )
};

export default Nav;